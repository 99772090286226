import React from "react"
import classes from "./button.module.scss"
import clsx from "clsx"
import { capitalize } from "../../../utils"

/**
 * @param {import("./types").Props["color"]} [color]
 */
export function getColorClass(color) {
  if (color) {
    return classes["Button" + capitalize(color)]
  } else {
    return classes.ButtonBlue
  }
}

export const baseButtonClass = classes.Button

/**
 * @param {import("./types").Props} props
 */
export function Button({ className, color, ...props }) {
  return <button className={clsx(classes.Button, getColorClass(color), className)} {...props} />
}
