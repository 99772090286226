import { api } from "./../utils/api"
import { actionCreator } from "../actions/actionCreator"
import { createStructuredSelector } from "reselect"
import { useTypedSelector } from "./useSelector"
import { useSelector, useStore } from "react-redux"


const initialState = {
  category: [],
  service: [],
  indicator: [],
  load: false,
};

export default function Category(state = initialState, action) {

  switch (action.type) {

    case "SET_CATEGORY":
      return {...state,category: action.payload};
    case "SET_SERVICE":
      return {...state,service: action.payload};
    case "SET_load":
      return {...state,load: action.payload};
    case "SET_Indicator":
      return {...state,indicator: action.payload};
    default:
      return state;
  }
}



export function getLoadService(state) {
  return state.Category.load
}
export function getCategory(state) {
  return state.Category.category
}
export function getService(state) {
  return state.Category.service
}

export const serviceId=(state,serviceName) =>{
  const data = (state.Category.service).find((key) => key.sysName === serviceName)
  return data?.serviceID
}
export function indicatorID(state,indicatorName) {
  const data = (state.Category.indicator).find((key) => key.sysName === indicatorName)
  return data?.indicatorID
}
export function serviceCategory(state,serviceCategory) {
  const data = (state.Category.category).find((key) => key.sysName === serviceCategory)
  return data?.serviceCategoryID
}

export const accessControlCategory = createStructuredSelector({
  load: getLoadService,
  category: getCategory,
  service: getService,
})

