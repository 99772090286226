import { createStructuredSelector } from "reselect"

/**
 * @returns {{
  auth: import("types").Role
  UserID: number
  OfficeID: number
  GroupID: number
  exp: number 
 * }}
 */
export const jwtData = (state = {}, action) => {
  switch (action.type) {
    case "SET_JWT_DATA":
      return Object.freeze(action.payload)
    default:
      return state
  }
}

/** @param {import("reducers").RootState} state */
export function roleSelector(state) {
  return state.jwtData.auth
}

/** @param {import("reducers").RootState} state */
export function groupSelector(state) {
  return state.jwtData.GroupID
}

/** @param {import("reducers").RootState} state */
export function userIDSelector(state) {
  return state.jwtData.UserID
}

/** @param {import("reducers").RootState} state */
export function officeIDSelector(state) {
  return state.jwtData.OfficeID
}

/** @param {import("reducers").RootState} state */
export function currentOfficeIDSelector(state) {
  return state.office?.officeID ?? state.jwtData.OfficeID
}

/** 
 * @typedef {{
    role: import("types").Role;
    officeID: number;
    groupID: number;
   }} AccessControl
 */

/** @type {import("reselect").Selector<import("reducers").RootState, AccessControl>} */
export const accessControlSelector = createStructuredSelector({
  role: roleSelector,
  officeID: officeIDSelector,
  groupID: groupSelector,
})
