import React from "react"
import RcTooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"
import classes from "./tooltip.scss"
const defaultTrigger = ["hover"]

/** @param {import("rc-tooltip/lib/Tooltip").TooltipProps & {title: string}} props */
export function Tooltip({ title,EventNone, ...props }) {
  return <RcTooltip  overlay={<span className={classes.ToolTipIndex}>{title}</span>} trigger={!EventNone ? defaultTrigger : ""} placement="top" {...props} />
}
