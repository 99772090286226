import { imagePath } from "./api"

export function parseNewsHTML(html) {
  const doc = new DOMParser().parseFromString(html, "text/html")
  const imgs = [...doc.querySelectorAll("img")].map((i) =>
    i.src
      .replace(/.+\/images\/uploads/, `${imagePath}uploads`)
      .replace(/.+\/images\/news/, imagePath + "news")
  )
  // console.log(html)
  // console.log(html.replace(/<img(.+)\/>/gm,"").replace(/<p><\/p>/g,""))
  return { content: html.replace(/<img(.+?)\/>/gm, "").replace(/<p><\/p>/g, "").replace("\\n \n" + "\\n", "").replace("\\n", "").replace("\\n", ""), imgs }
  //  return { content: html.replace(/<p><img(.+)<\/p>/gm), imgs }
}

//return { content: doc.body.textContent, imgs }
