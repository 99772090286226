import axios from "axios"

const useLocal = false
let URL = window.location.hostname;

// let apiURL = "https://test.dreamteam.world:8443" //для домашнего использования
// export const imagePath = "https://test.dreamteam.world/images/" //для домашнего использования

let apiURL = `https://${URL}:8443`                   //для теста и продуктива
export const imagePath = `https://${URL}/images/`    //для теста и продуктива

if (process.env.NODE_ENV === "development") {
  if (useLocal) {
    apiURL = "http://localhost:3001"
  }
}

export const api = axios.create({
  baseURL: apiURL + `/api`,
  timeout: 1000 * 15,
  // withCredentials: true
})
export const videoPath = `https://dreamteam.world/images/video/nn.mp4`