export const SET_SCROLL_ID = "SET_SCROLL_ID"

export const scrollPosition = (state = null, action) => {
  switch (action.type) {
    case "SET_SCROLL_ID":
      return action.payload
    default:
      return state
  }
}
