import React from 'react'
import classes from './badge.module.scss'
import clsx from 'clsx'

const Badge = ({ children, className, onClick }) => {
    return (
        <div className={classes.Badge} onClick={onClick}>
            <div className={clsx(classes.BadgeContent, className)}>
                {children}
            </div>
        </div>
    )
}

export default Badge;