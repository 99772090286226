import React, { useMemo } from "react"
import { useOffices } from "../../../queries/offices"
import { empty } from "../../../utils/typedEmptyArray"
import { Select } from "../select/select"

export function OfficeSelect({ all = false, onChange, filter = (_) => true, ...props }) {
  const { data: offices = empty } = useOffices()
  const officeOptions = useMemo(() => {
    const options = offices.filter(filter).map((o) => ({ label: o.title, value: o.officeID }))
    if (all) {
      options.unshift({ label: "Все офисы", value: 0 })
    }
    return options
  }, [offices, filter, all])

  return <Select placeholder="Выберите..." options={officeOptions} onChange={onChange} {...props} />
}
