export const saleModal = (state = false, action) => {
  switch (action.type) {
    case "SET_OPEN":
      return true
    case "SET_CLOSE":
      return false
    case "SET_STANDART":
      return !state
    default:
      return state
  }
}
