/**
 * @param {import("../types").Office} state
 * @returns {import("../types").Office} state
 */
export const office = (state = {}, action) => {
  switch (action.type) {
    case "SET_OFFICE":
      return action.payload
    default:
      return state
  }
}
