import clsx from "clsx"
import clamp from "lodash/clamp"
import debounce from "lodash/debounce"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { animated, useSpring } from "react-spring"
import classes from "./scroll.module.scss"
const debug = require("debug")("scroll")

const ScrollContext = React.createContext({ scroll: 0, immediate: false })

const distance = 60
const duration = 100
const someMinimalIgnoredDistance = distance / 10

/** @type {React.FC<{us: ReturnType<typeof useScroll>}>} */
export const ScrollWrap = ({ children, us, disabled = false }) => {
  const hasScrollBottom = us.state.max - us.state.scroll > someMinimalIgnoredDistance
  const hasScrollTop = us.state.scroll > someMinimalIgnoredDistance
  return (
    <div className={classes.Wrap}>
      <div
        className={clsx(classes.ArrowTop, hasScrollTop && !disabled && classes.Show)}
        onMouseDown={us.startScrollingUp}
        onMouseUp={us.stopScrolling}
        onContextMenu={us.stopScrolling}
      ></div>
      <div className={clsx(classes.ScrollGradient, classes.TopScroll, hasScrollTop && classes.Show)}></div>
      <ScrollContext.Provider value={us.state}>{children}</ScrollContext.Provider>
      <div
        className={clsx(classes.ArrowBottom, hasScrollBottom && !disabled && classes.Show)}
        onMouseDown={us.startScrollingDown}
        onMouseUp={us.stopScrolling}
        onContextMenu={us.stopScrolling}
      ></div>
      <div className={clsx(classes.ScrollGradient, hasScrollBottom && classes.Show)}></div>
    </div>
  )
}

function isLeftClick(ev) {
  return ev.buttons === 1
}

export function useScroll() {
  const [state, setState] = useState({ scroll: 0, max: 0, immediate: false })
  const dir = useRef(0)

  const updateScrollState = useCallback((scroll, max) => {
    setState((s) => ({ scroll, max: max ?? s.max, immediate: true }))
  }, [])
  const continueScroll = () => {
    if (dir.current) {
      setState((state) => {
        const endValue = clamp(state.scroll + distance * dir.current, 0, state.max)
        if (endValue === 0 || endValue === state.max) {
          dir.current = 0
          debug("forcing scroll stop", endValue, dir.current)
        }
        return {
          ...state,
          scroll: endValue,
          immediate: false,
        }
      })
      setTimeout(continueScroll, duration)
    }
  }
  const stopScrolling = useCallback(() => {
    dir.current = 0
  }, [])
  /* eslint-disable react-hooks/exhaustive-deps */
  const startScrollingDown = useCallback((ev) => {
    if (!isLeftClick(ev)) return
    dir.current = +1
    continueScroll()
  }, [])
  const startScrollingUp = useCallback((ev) => {
    if (!isLeftClick(ev)) return
    dir.current = -1
    continueScroll()
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */
  return { updateScrollState, startScrollingDown, startScrollingUp, stopScrolling, state }
}

export const AnimatedOuterElement = React.forwardRef((props, ref) => {
  const { scroll, immediate } = useContext(ScrollContext)
  const spring = useSpring({
    from: { scroll: 0 },
    to: { scroll },
    immediate,
    config: { duration },
  })
  return <animated.div scrollTop={spring.scroll} {...props} ref={ref} />
})

export function useVirtualizedScroll(onScroll) {
  const innerRef = useRef()
  const outerRef = useRef()
  const handleScroll = useCallback(
    debounce(() => {
      const scroller = outerRef.current
      const content = innerRef.current
      if (!scroller || !content) return
      const scroll = scroller.scrollTop
      const max = content.offsetHeight - scroller.offsetHeight + 2
      onScroll(scroll, max)
    }, 50),
    [innerRef]
  )

  return {
    innerRef,
    outerRef,
    onScroll: handleScroll,
    outerElementType: AnimatedOuterElement,
    className: classes.Scroll,
  }
}

export function ScrollResizer({ height, length, update }) {
  useEffect(() => {
    update()
  }, [height, length, update])
  return null
}
