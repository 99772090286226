import clsx from "clsx"
import React from "react"
import { useStraps } from "../../../queries/straps"
import { imagePath } from "../../../utils/api"
import { Tooltip } from "../../tooltip/tooltip"
import classes from "./strap.module.scss"

export function Strap({ strapID, className, ...props }) {
    const { data: straps } = useStraps()
    if (!straps) return null
    const strap = straps[strapID]

    return (
        <Tooltip title={strap?.title}>
            <img
                className={clsx(classes.Img, className)}
                src={imagePath + "straps/" + strap?.image}
                alt={strap?.title ?? ""}
                {...props}
            />
        </Tooltip>
    )
}
