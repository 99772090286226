/** @typedef {import("../types").User} User */

/**
 * @param {User} state
 * @returns {User}
 */
export const user = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER":
      return action.payload
    default:
      return state
  }
}
