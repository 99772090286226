import { Button } from "components/ui"
import MobileDetect from "mobile-detect"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { hot } from "react-hot-loader/root"
import { ReactQueryConfigProvider } from "react-query"
import { ReactQueryDevtools } from "react-query-devtools"
import { connect, useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { operation } from "../../actions/operation"
import Preloader from "../../components/preloader/preloader"
import { useHistory } from "react-router-dom"
import CheckAuth from "../../pages/check-auth/check-auth"
import { getBasePath } from "../../utils/getBasePath"
import classes from "./app.module.scss"
import { MyCustomContainer, MyCustomToast } from "../notification/custom-notification"
import { ToastProvider } from 'react-toast-notifications'
import { actionCreator } from "../../actions/actionCreator"
import { api } from "../../utils"
const LazyMain = React.lazy(() => import("./LazyMain"))

/** @type {import("react-query").ReactQueryProviderConfig} */
const defaultQueryConfig = {
  queries: { staleTime: 1000 * 60 * 5 },
}

export const App = ({ jwtData, getUser, getOffice }) => {
  const [isMobileDevice, setMobileDevice] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  // const [bodyScroll, setBodyScroll] = useState(null)
  /** @type {import("../../types").Role} */
  const role = jwtData.auth
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const history = useHistory()
  const scrollId = useSelector(state => state.scrollPosition)

  useEffect(() => {
    if (!history.location.pathname.includes("news") && scrollId) {
      dispatch(actionCreator.setScrollID(null))
    }
  }, [history.location.pathname])
  useEffect(() => {
    dispatch(Getcategory())
  }, [user])

  useEffect(() => {
    if (!Object.keys(jwtData).length) {
      return
    }
    const { UserID: userID, OfficeID: officeID, sub } = jwtData
    getUser(userID)
    // if (sub !== "admin") {
    getOffice(officeID)
    // }
  }, [jwtData, getUser, getOffice])
  const checkIsMobile = () => {
    const md = new MobileDetect(window.navigator.userAgent)
    const isMobile = md.mobile() && document.documentElement.clientWidth < 568
    isMobile ? setMobileDevice(true) : setMobileDevice(false)
  }

  useEffect(() => {
    checkIsMobile()
    window.addEventListener("resize", checkIsMobile)
    window.addEventListener("orientationchange", checkIsMobile)
    return () => {
      window.removeEventListener("resize", checkIsMobile)
      window.removeEventListener("orientationchange", checkIsMobile)
    }
  }, [])

  const openMobileMenu = () => {
    setMenuOpen(true)
    // if (!isMenuOpen) {
    //   document.body.classList.add("no-scroll-menu")
    //   bodyScroll.destroy()
    //   setMenuOpen(true)
    // }
  }

  const closeMobileMenu = () => {
    setMenuOpen(false)
    // if (isMenuOpen) {
    //   initBodyScroll()
    //   document.body.classList.remove("no-scroll-menu")
    //   setMenuOpen(false)
    // }
  }

  const toggleMobileMenu = () => (isMenuOpen ? closeMobileMenu() : openMobileMenu())
  const location = useLocation()

  const close = useRef(closeMobileMenu)
  close.current = closeMobileMenu
  const basePath = getBasePath(location.pathname)
  useEffect(() => {
    close.current()
  }, [basePath])



  return (
    <>
      <ErrorBoundary
        fallbackRender={({ resetErrorBoundary }) => (
          <div className={classes.LazyMainError}>
            <div>Query Dev Tools Error</div>
            <Button
              onClick={() => {
                localStorage.reactQueryDevtoolsOpen = false
                resetErrorBoundary()
              }}
            >
              Закрыть
            </Button>
          </div>
        )}
      >
        <ReactQueryDevtools />
      </ErrorBoundary>
      <ReactQueryConfigProvider config={defaultQueryConfig}>
        <ScrollToTop />
        <CheckAuth isMobile={isMobileDevice}>
          {/*{ {isMobileDevice && <MobileScreen />} }*/}
          <ToastProvider components={{ ToastContainer: MyCustomContainer, Toast: MyCustomToast }} autoDismissTimeout={3000}>
            <ErrorBoundary
              fallbackRender={({ resetErrorBoundary }) => (
                <div className={classes.LazyMainError}>
                  <div>Что-то пошло не так</div>
                  <div>Попробуйте перезагрузить страницу</div>
                </div>
              )}
            >
              <Suspense fallback="">
                <LazyMain
                  isMobileDevice={isMobileDevice}
                  isMenuOpen={isMenuOpen}
                  toggleMobileMenu={toggleMobileMenu}
                  role={role}
                />
              </Suspense>

              {/* <Test /> */}
            </ErrorBoundary>
          </ToastProvider>
        </CheckAuth>
        <Preloader />

      </ReactQueryConfigProvider>


    </>
  )
}

// eslint-disable-next-line
const Test = () => {
  if (Math.random() < 0.5) throw new Error("test")
  else return null
}

const mapStateToProps = (state, ownProps) => {
  return {
    jwtData: state.jwtData,
    isShowPreloaderPage: state.isShowPreloaderPage,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser(id) {
      dispatch(operation.getUser(id))
    },
    getOffice(id) {
      dispatch(operation.getOffice(id))
    },
  }
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

export default process.env.NODE_ENV === "development" ? hot(ConnectedApp) : ConnectedApp

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}


export const Getcategory = () => {
  return dispatch => {
    api.get("/service/category/list")
      .then(res => {
        dispatch(actionCreator.SetCategory(res.data));
      })
    api.get("/service/list")
      .then(res => {
        dispatch(actionCreator.SetService(res.data));
      })
    api.get("/indicator/list").then(res => {
      dispatch(actionCreator.SetIndicator(res.data))
      dispatch(actionCreator.SetLoad(true))
    }
    )
    api.get("/group/list").then(res => {
      const groups = {}
      res.data.forEach(group => {
        groups[group.groupID] = {
          officeList: group.officeList.map(office => office.officeID),
          groupName: group.groupName
        }
      })
      dispatch(actionCreator.SetGroups(groups))
    })
  };
};
