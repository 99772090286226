// @ts-check
import { api } from "../utils/api"
/** @typedef {import("../swagger-types").definitions["Товар в корзине"]} BasketEntry  */

const initialState = {
  list: /** @type {BasketEntry[]} */ ([]),
  loading: false,
}

const BASKET_ADD_START = "BASKET_ADD_START"
const BASKET_ADD_SUCCESS = "BASKET_ADD_SUCCESS"
const BASKET_ADD_FAILURE = "BASKET_ADD_FAILURE"
const BASKET_DATA = "BASKET_DATA"

export async function getBasket() {
  const arr = { data: [] }
  const res = await api.get("/shop/basket/my/list").then((res)=> res).catch((e)=>arr)
  return res.data
}

export const actions = {
  getBasket: () => {
    return function (dispatch) {
      getBasket()
        .then((payload) => dispatch({ type: "BASKET_DATA", payload }))

    }
  },
  submitItem: (id) => {
    return function (dispatch) {
      api
        .put("/shop/basket/" + id + "/approve")
        .then((_res) => {
          console.log(_res)
          dispatch(actions.getBasket())
        })
        .catch(() => {
          //failure
        })
    }
  },
  addToBasket: (id,showToast) => {
    return function (dispatch) {
        dispatch({ type: BASKET_ADD_START })
        api
          .post("/shop/basket/" + id)
          .then(() => {
            dispatch({ type: BASKET_ADD_SUCCESS })
            dispatch(actions.getBasket())
            setTimeout(()=> showToast(true),350)
          })
          .catch(() => {
            dispatch({ type: BASKET_ADD_FAILURE })
            setTimeout(showToast(false),500)
          })
    }
  },
  removeFromBasket: (id,addToast) => {
    return function (dispatch) {
      // dispatch({ type: BASKET_REMOVE_START, payload: item })
      api.delete("/shop/basket/" + id).then(() => {
        // dispatch({ type: BASKET_REMOVE_SUCCESS })
        dispatch(actions.getBasket())
        setTimeout(()=>addToast("Покупка отменена успешно", { appearance: 'success', id: 'cancelPurchase', autoDismiss: true,  placement: "bottomCenter" })
          ,100)
      })
      .catch(() =>  setTimeout(()=>addToast("Покупка не отменена ", { appearance: 'error', id: 'cancelPurchase', autoDismiss: true,  placement: "bottomCenter" })
        ,100))
    }
  },
}

/**
 * @returns {typeof initialState}
 */
export const basket = (state = initialState, action) => {
  switch (action.type) {
    case BASKET_DATA:
      /** @type {BasketEntry[]} */
      const list = [...action.payload]
      list.sort((a, b) => a.addTimestamp - b.addTimestamp)
      return { loading: false, list }

    case BASKET_ADD_START:
      return { ...state, loading: true }

    // case BASKET_ADD_SUCCESS:
    case BASKET_ADD_FAILURE:
      return { ...state, loading: false }

    default:
      return state
  }
}
