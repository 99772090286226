import React from "react"
import { Empty } from "../empty/Empty"
import classes from "./table.module.scss"

export const tableClasses = { Table: classes.Table }

export const Table = React.memo(
    /**  @param {{cols: import("./types").Column[], rows: import("./types").Row[], rowKey: string}} props */
    ({ cols, rows, rowKey }) => {
        if (rows.length === 0) {
            return <Empty>Нет данных</Empty>
        }
        return (
            <table className={classes.Table}>
                <thead>
                    <tr>
                        {cols.map(({ prop, title, headerComponent: Cmp = React.Fragment }) => (
                            <th key={prop}>
                                <Cmp>{title}</Cmp>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((r) => (
                        < tr key={r[rowKey]} >
                            {cols.map(({ prop, valueComponent: Cmp = Fragment }) => (
                                <td key={prop} className={classes.TD}>
                                    <Cmp row={r} prop={prop}>
                                        {r[prop]}
                                    </Cmp>
                                </td>
                            ))
                            }
                        </tr>
                    ))}
                </tbody>
            </table >
        )
    }
)

function Fragment({ children }) {
    return <React.Fragment>{children}</React.Fragment>
}
