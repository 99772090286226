import React, { useEffect } from "react"
import clsx from "clsx"
import classes from "./container.module.scss"
import { prefetchOffices } from "queries/offices"

export const Container = ({ children, className = "" }) => {
  useEffect(() => {
    prefetchOffices()
  }, [])
  return <div className={clsx(classes.Container, className)}>{children}</div>
}
