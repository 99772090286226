import React from "react"
import { Link } from "react-router-dom"

export function UserLink({ id, children, ...props }) {
  return (
    <Link to={"/user/" + id} {...props}>
      {children}
    </Link>
  )
}
