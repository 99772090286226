import { combineReducers } from "redux"
import { basket } from "./basket"
// import { token } from './token'
import { jwtData } from "./jwt-data"
import { office } from "./office"
import { page } from "./page"
import { showPreloaderPage } from "./showPreloaderPage"
import { user } from "./user"
import Category from "./category"
import { saleModal } from "./saleModal"
import { scrollPosition } from "./scrollPosition"
import { groups_reducer } from "./groups"

export const rootReducer = combineReducers({
  // token,
  jwtData,
  isShowModalSale: saleModal,
  user,
  office,
  page,
  isShowPreloaderPage: showPreloaderPage,
  basket,
  Category,
  scrollPosition,
  groups: groups_reducer,
})

/** @typedef {ReturnType<typeof rootReducer>} RootState */
