// @ts-check
import { useQuery } from "react-query"
import { api } from "../utils"
import fromPairs from "lodash/fromPairs"
import { empty } from "../utils/typedEmptyArray"
const debug = require("debug")("straps query")

async function loadStraps() {
  /** @type {import("axios").AxiosResponse<import("../types").Strap[]>} */
  const res = await api.get("/strap/list")
  debug("loaded straps", res.data)
  const data = fromPairs(res.data.map((s) => /** @type {[Number, import("../types").Strap]} */ ([s.strapID, s])))
  return data
}

export function useStraps() {
  return useQuery("straps", loadStraps, { staleTime: Infinity, cacheTime: Infinity })
}

export async function loadStrapsJobs(id) {
  /** @type {import("axios").AxiosResponse<import("../types").Strap[]>} */
  const res = await api.get("/strap/list?jobtitleID="+id)
  const data = fromPairs(res.data.map((s) => /** @type {[Number, import("../types").Strap]} */ ([s.strapID, s])))
  return data
}
export function useStrapsJobs(id) {
  return useQuery("straps"+id, ()=> loadStrapsJobs(id), { staleTime: Infinity, cacheTime: Infinity })
  // return useQuery(["straps", id],()=> loadStrapsJobs(id), { enabled: id, cacheTime: Infinity })
}

