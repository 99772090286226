import clsx from "clsx"
import React from "react"
import { animated, config, useSpring } from "react-spring"
import { formatAmount } from "../../../utils/format"
import classes from "./progress-bar.module.scss"
const debug = require("debug")("progress-bar") // eslint-disable-line

export const ProgressBar = ({ percent = 0, amount = 0, mobile, plan }) => {
  const targetWidth = `${Math.min(percent, 100)}%`
  const props = useSpring({
    from: { width: `0%` },
    to: { width: targetWidth },
    delay: 200,
    config: config.molasses,
  })

  return (
    <div className={clsx(classes.Wrapper, mobile && classes.MobileWrapper, plan && classes.Plan)}>
      <animated.div className={clsx(classes.Line, classes.LineRed)} style={props} />
      <animated.div
        className={clsx(classes.Line, classes.LineYellow, percent >= 50 && classes.LineVisibleYellow)}
        style={props}
      />
      <animated.div
        className={clsx(classes.Line, classes.LineGreen, percent >= 100 && classes.LineVisibleGreen)}
        style={props}
      />
      <span className={clsx(classes.Price, "number")}>{formatAmount(amount)}</span>
      <span className={clsx(classes.Percent, "number")}>{percent.toFixed(1)} %</span>
    </div>
  )
}
