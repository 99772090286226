// @ts-check
import { queryCache, useQuery } from "react-query"
import { api, toForm } from "../utils"
import omit from "lodash/fp/omit"
import axios from "axios"
import { serviceName } from "../reducers/category"
const debug = require("debug")("offices query")

/** @typedef {import("../types").Office} Office */

async function fetchOffices() {
  /** @type {import("axios").AxiosResponse<Office[]>} */
  const res = await api.get("/office/list")
  debug("office list", res.data)
  return res.data
}
async function fetchOfficesWithBalance() {
  /** @type {import("axios").AxiosResponse<Office[]>} */
  const res = await api.get("/office/list?withBalance=true")
  debug("office list", res.data)
  return res.data

}
/**
 * @param {number} id
 */
async function fetchOffice(id) {
  /** @type {import("axios").AxiosResponse<Office>} */
  const res = await api.get(`/office/${id}/get`)
  debug("fetching office " + id, res.data)
  return res.data
}
/**
 * @param {import("react-query").QueryConfig<Office[]>} [config]
 */
export function useOffices(config) {
  return useQuery("offices", fetchOffices, config)
}
export function useOfficesWithBalance(config) {
  return useQuery("officesBalance", fetchOfficesWithBalance, config)
}

/**
 * @param {import("react-query").QueryConfig<Office[]>} [config]
 */
export function prefetchOffices(config = {}) {
  return queryCache.prefetchQuery("offices", fetchOffices, config)
}

/**
 * @param {number} id
 * @param {import("react-query").QueryConfig<Office>} [config]
 */
export function useOffice(id, config) {
  return useQuery(["offices", id], () => fetchOffice(id), {
    initialData: () => {
      /** @type {Office[] | undefined} */
      const cache = queryCache.getQueryData("offices")
      const office = cache?.find((o) => o.officeID === id)
      // debug("initial office data " + id, cache, office)
      return office
    },
    initialStale: true,
    enabled: id,
    suspense: true,
    ...config,
  })
}

/**
 * @param {number} id
 * @param {import("react-query").QueryConfig<Office>} [config]
 */
export function prefetchOffice(id, config = {}) {
  return queryCache.prefetchQuery(["offices", id], () => fetchOffice(id), config)
}

/**
 * @param {{officeID: number, form: Partial<Office>}} props
 */
export async function updateOffice({ officeID, form }) {
  // await new Promise((resolve) => setTimeout(resolve, 15000))
  const res = await api.put(`/office/${officeID}/update`, toForm(form))
  return res.data
}

/** @param {{data: {participantID: number, amount: number, note: string}[]}} props */
export async function addBonusOffice(data) {
  const res = await api.post("/office/add-bonus", data)
  return res.data
}

// Перевести коины со счёта офиса на счёт пользователя
// { "note": "string", "amount": 0, "officeID": 0, "userID": 0 }
export async function addBonusUser(data) {
  let d = { ...data }
  const res = await api.post("/coin/transfer/officetouser", d)
  return res.data
}

// Перевести коины со счёта пользователя на счёт офиса
// { "note": "string", "amount": 0, "officeID": 0, "userID": 0 }
export async function addBonusUsrToOffice(data) {
  let d = { ...data }
  const res = await api.post("/coin/transfer/usertooffice", d)
  return res.data
}

export async function saleList(params) {
  const res = await api.get("/sale/list", { params: { count: 20, sort: "saleTime,DESC", ...params } })
  return res.data
}
export function useSales(params = {}, config) {
  return useQuery("salesHistory", () => saleList(params), config)
}
export async function deleteSale(saleID, invalidData, state, userID) {
  const res = await api.delete("sale", {
    data: [saleID]
  });
  setTimeout(() => queryCache.invalidateQueries(["cheque", userID], { refetchInactive: true }), 1)
  return res.data
}
export async function sendUpdate(officeID, value) {
  const res = await api.put(`/office/${officeID}/update`, toForm(value))
  setTimeout(() => {
    queryCache.invalidateQueries("offices")
    queryCache.invalidateQueries("agents")
    queryCache.invalidateQueries("RatingAgent")
    queryCache.invalidateQueries("rating/company")
  }, 1000)

  return res.data
  // await dispatch(loadOffices()).then(() => console.log("yes result of dispatch is thenable"))
}
export async function fetchRatingAgent() {
  const res = await api.get("/rating/agents")
  return res.data
}
export function useRatingAgent() {
  return useQuery("RatingAgent", fetchRatingAgent, { suspense: true })
}
export async function fetchRatingCompany() {
  const res = await api.get("rating/company")
  return res.data
}
export function useRatingCompany() {
  return useQuery("RatingCompany", fetchRatingCompany, { suspense: true })
}
