// @ts-check
import clsx from "clsx"
import clamp from "lodash/clamp"
import React, { forwardRef, useCallback } from "react"
import { parsePositiveInt } from "../../../utils"
import classes from "./input.module.scss"

export const Input = forwardRef(
  /**@param {import("./types").Props} props*/
  ({ className, value, onChange, ...props }, ref) => {
    const handleInput = useCallback(
      (ev) => {
        onChange(ev.target.value)
      },
      [onChange]
    )
    return (
      <input maxLength={props.maxLength} ref={ref} className={clsx(classes.Input, className)} value={value} onChange={handleInput} {...props} />
    )
  }
)

/**
 * @param {{register: import("react-hook-form").UseFormMethods["register"], name: string, className: string}} props
 */
export function UncontrolledInput({ register, name, className, ...props }) {
  return <input className={clsx(classes.Input, className)} name={name} ref={register} {...props} />
}
export function BasisInput({ register, name, className, onChange, value, ...props }) {
  return <input className={clsx(classes.Input, className)} name={name} onChange={onChange} value={value}  {...props} />
}

export const PositiveIntInput =
  /**@param {import("./types").Props<number> & {min: number, max: number}} props*/
  ({ className, onChange, value, min, max, ...props }) => {
    const handleInput = useCallback(
      /** @param {string} v */
      (v) => {
        onChange(clamp(parsePositiveInt(v), min, max))
      },
      [max, min, onChange]
    )
    return <Input className={clsx("number", className)} onChange={handleInput} value={String(value)} {...props} />
  }

/**
 * @param {{register: import("react-hook-form").UseFormMethods["register"], name: string, className: string}} props
 */
export function UncontrolledIntInput({ register, name, className, ...props }) {
  return (
    <input
      className={clsx(classes.Input, className)}
      name={name}
      ref={register({ validate: { int: (value) => String(parseFloat(value)) === String(value) } })}
      {...props}
    />
  )
}
