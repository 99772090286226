// @ts-check

/**
 * @param {string | null | undefined} str
 * @returns {string} `Str`
 */
export function capitalize(str) {
  if (typeof str === "string") {
    return str[0].toUpperCase() + str.slice(1)
  } else {
    return ""
  }
}
