/** @type {<T>(source:T) => T} */
export const omitUndefined = (source) => {
    const res = {}
    for (const key in source) {
        if (source[key]) {
            res[key] = source[key]
        }
    }
    return res
}
