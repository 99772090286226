import React from 'react';
import classes from './btn-label.module.scss';
import clsx from 'clsx';

const BtnLabel = ({ label, className }) => {
    if (!className) {
        className = classes.BgDefault;
    }

    return (
        <div className={clsx(classes.BtnLabel, className)}>
            <p className={classes.Label}>{label}</p>
        </div>
    )
}

export default BtnLabel;