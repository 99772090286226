import React from "react"
import classes from "./notification.module.scss"
import clsx from "clsx"
import { useToasts } from "react-toast-notifications"

export function MyCustomToast  ({ appearance, children }) {
  const { addToast, removeToast, removeAllToasts, updateToast, toastStack } = useToasts();

  return (
    <div className={classes.BoxNotification}>
      <div className={classes.topBlock}>
        <div className={classes.ImgBlock}>
          {appearance === "success" &&
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.4997 33.5416C8.64011 33.5416 1.45801 26.3595 1.45801 17.4999C1.45801 8.64035 8.64011 1.45825 17.4997 1.45825C26.3592 1.45825 33.5413 8.64035 33.5413 17.4999C33.5413 26.3595 26.3592 33.5416 17.4997 33.5416ZM17.5 30.6249C24.7487 30.6249 30.625 24.7487 30.625 17.4999C30.625 10.2512 24.7487 4.37494 17.5 4.37494C10.2513 4.37494 4.375 10.2512 4.375 17.4999C4.375 24.7487 10.2513 30.6249 17.5 30.6249ZM22.3018 12.0937L14.583 19.8125L11.2392 16.4687L9.17676 18.5311L14.583 23.9373L24.3642 14.1561L22.3018 12.0937Z"
                  fill="#1BC167"/>
          </svg>
          }
          {appearance === "error" &&
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M32.7272 23.265L22.5111 5.80065C21.4854 4.02262 19.5738 2.91905 17.5065 2.91663C15.4389 2.91421 13.5266 4.01323 12.4882 5.80073L2.26827 23.2715C1.20854 25.0534 1.18457 27.2862 2.21103 29.1071C3.23836 30.9296 5.16195 32.0638 7.25427 32.0808L27.7262 32.0809C29.8402 32.0603 31.7602 30.9296 32.7873 29.1088C33.8133 27.2901 33.7904 25.0622 32.7272 23.265ZM4.78028 24.7533L15.0078 7.26962C15.5257 6.37804 16.4757 5.83209 17.5028 5.83329C18.5299 5.8345 19.4795 6.3827 19.9889 7.2657L30.2131 24.7439C30.749 25.6498 30.7605 26.7652 30.2468 27.6757C29.7324 28.5876 28.7707 29.1539 27.7117 29.1643L7.26594 29.1642C6.22964 29.1557 5.26612 28.5876 4.75163 27.6749C4.23772 26.7632 4.24972 25.6454 4.78028 24.7533ZM17.5005 26.2476C18.3062 26.2476 18.9593 25.5946 18.9593 24.7892C18.9593 23.9838 18.3062 23.3309 17.5005 23.3309C16.6948 23.3309 16.0417 23.9838 16.0417 24.7892C16.0417 25.5946 16.6948 26.2476 17.5005 26.2476ZM18.9636 11.6642H16.046V21.8725H18.9636V11.6642Z"
                  fill="#ED4C4C"/>
          </svg>
          }
        </div>
        <div className={classes.TextBlock}>
          {children}
        </div>
      </div>
      <div className={classes.downBlock}>
        <div onClick={()=> {
          removeToast("purchase")
          removeToast("cancelPurchase")
          removeToast("battles")
        }}
             className={classes.btnClose}>
          Закрыть
        </div>
      </div>

    </div>
  )
}
export function MyCustomContainer (props) {
  let position;
  const placements = props.children._owner.updateQueue.baseState.toasts[0]?.placement
  if (placements === "rightTop") { position = classes.rightTop}
  if (placements === "bottomCenter") { position = classes.bottomCenter}
  if (placements === "topCenter") { position = classes.topCenter}
  return (
    <div className={clsx(classes.container, position)}>
      {props.children}
    </div>
  )
}
