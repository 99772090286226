import React, { useEffect } from "react"
import { connect } from "react-redux"
import Cookies from "js-cookie"
import jwtDecode from "jwt-decode"
import Auth from "../auth/auth"
import { api } from "../../utils/api"

import { actionCreator } from "../../actions/actionCreator"

const CheckAuth = ({ children, setJwt, page, setPage, setShowPreloaderPage, isShowPreloaderPage, isMobile }) => {
  useEffect(() => {
    const token = Cookies.get("token")
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`
    api
      .get("/auth/check")
      .then(() => {
        setJwt(token)
        setPage("content")
      })
      .catch((err) => {
        setPage("auth")
        setShowPreloaderPage(false)
        console.error(err)
      })
  }, [setJwt, setPage, setShowPreloaderPage])

  switch (page) {
    case "content":
      return children
    default:
      return <Auth isMobile={isMobile} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    page: state.page,
    isShowPreloaderPage: state.isShowPreloaderPage,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setJwt(token) {
      dispatch(actionCreator.setJwtData(jwtDecode(token)))
    },
    setPage(page) {
      dispatch(actionCreator.setPage(page))
    },
    setShowPreloaderPage(payload) {
      dispatch(actionCreator.setShowPreloaderPage(payload))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckAuth)
