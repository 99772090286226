import { SET_SCROLL_ID } from "../reducers/scrollPosition"

export const actionCreator = {
  setJwtData: data => ({
    type: `SET_JWT_DATA`,
    payload: data
  }),
  setUser: data => ({
    type: `SET_USER`,
    payload: data
  }),
  setOffice: data => ({
    type: `SET_OFFICE`,
    payload: data
  }),
  setScrollID: data => ({
    type: SET_SCROLL_ID,
    payload: data
  }),
  setPage: page => ({
    type: `SET_PAGE`,
    payload: page
  }),
  setShowPreloaderPage: payload => ({
    type: `SET_SHOW_PRELOADER_PAGE`,
    payload
  }),
  SetCategory: data => ({
  type: `SET_CATEGORY`,
  payload: data
}),
  SetService: data => ({
  type: `SET_SERVICE`,
  payload: data
}),
  SetLoad: data => ({
  type: `SET_load`,
  payload: data
}),
  SetIndicator : data => (
    {
      type: "SET_Indicator",
      payload: data
    }
  ),
  SetModalOpen : data => ({
    type: "SET_OPEN",
      payload: data
  }),
  SetGroups: data => ({
    type: "SET_GROUPS",
      payload: data
  }),
  SetModalClose : data => ({
    type: "SET_CLOSE",
      payload: data
  }),
  SetModalStandart : data => ({
    type: "SET_STANDART",
      payload: data
  })
}
export const actionService = {
  masterclass: "masterclass",
  invest: "invest",
  analytics: "analytics",
  ks: "infoservice",
  IP: "investportfolio",
  webinar: "webinar",
  course: "class",
  insurance: "insurance",
  cheque: "cheque"
}
