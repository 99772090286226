import { LoaderWrapHeight } from "components/ui/loader/loader-wrap-height"
import React, { Fragment, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { setShowPreloaderPage } from "../../reducers/showPreloaderPage"
import classes from "./preloader.module.scss"

const Preloader = ({ isShowPreloaderPage }) => {
  return (
    <Fragment>
      {isShowPreloaderPage && (
        <div className={classes.Preloader}>
          <div className={classes.Circle} />
          <div className={classes.Circle} />
          <div className={classes.Circle} />
        </div>
      )}
    </Fragment>
  )
}

export const PreloaderInline = ({ className }) => (
  <LoaderWrapHeight className={className}>
    <div className={classes.Circle}/>
    <div className={classes.Circle}/>
    <div className={classes.Circle}/>
    <div className={classes.Circle}/>

  </LoaderWrapHeight>
)

const mapStateToProps = (state, ownProps) => {
  return {
    isShowPreloaderPage: state.isShowPreloaderPage,
    ...ownProps,
  }
}

export default connect(mapStateToProps)(Preloader)

export function HidePreloader() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setShowPreloaderPage(false))
  }, [dispatch])
  return null
}
