import clsx from "clsx"
import set from "lodash/fp/set"
import { checkPropTypes } from "prop-types"
import React, { useMemo, useState, useEffect } from "react"
import { Button } from "../button/button"
import classes from "./form.module.scss"

const debug = require("debug")("form")

export const Form = ({
  children,
  fields = [],
  fio,
  initialValue = {},
  offiBalance,
  usrBalance,
  max,
  Notice,
  onCancel,
  onSubmit,
  textLength,
  title = "",
  no = "Отмена",
  yes = "Сохранить",

}) => {
  const [state, setState] = useState({})
  const [initial] = useState(initialValue)
  const handleSubmit = () => {
    let res = state
    debug("submitting", res)
    return onSubmit(res)
  }
  return (
    <div className={classes.Wrap}>
      {title && <div className={classes.Title}>{title}<br />
        {fio && <label style={{ fontSize: 12 }}> Доступно для перевода: {offiBalance} <br /> Получатель: {fio} </label>}
        {Notice && <label style={{ fontSize: 12 }}> Доступно для перевода: {usrBalance} DC <br /> </label>}
      </div>}
      {
        fields.map((i) => {
          const value = state[i.prop] ?? initial[i.prop] ?? ""
          return (
            <div style={{ width: "100%" }} key={i.prop}>
              <label className={clsx(classes.Label, i.inline && classes.LabelInline)}>
                <div >{i.title}</div>
                <i.component
                  value={value}
                  onChange={(v) => { setState(set([i.prop], v)) }}
                  {...i.props}
                  form={state}
                  max={max}
                  maxLength={textLength}
                  data={initialValue}
                />
              </label>
            </div>
          )
        })
      }
      {children}
      {fio &&
        <label style={{ fontSize: 12 }}><b style={{ fontSize: 14 }}>
          Внимание!</b> Коины спишутся со счёта офиса немедленно, однако на счёт сотрудника они будут зачислены только после подтверждения администратором
        </label>}
      {Notice &&
        <label style={{ fontSize: 12 }}>
          <b style={{ fontSize: 14 }}> Внимание!</b> Коины спишутся с вашего счёта немедленно, однако на счёт офиса они будут зачислены только после подтверждения администратором
        </label>}
      <div className={classes.Buttons}>
        {!!onCancel && (
          <Button color="white" onClick={onCancel}>
            {no}
          </Button>
        )}
        {offiBalance || usrBalance ?
          <Button onClick={!!state.amount && handleSubmit} color={!!state.amount ? null : 'white'} >{yes}</Button>
          :
          (!!onSubmit) && <Button onClick={handleSubmit} >{yes}</Button>}
      </div>
    </div >
  )
}

/** @type {import("./types").FormFC} */
export const FormTyped = ({ fields, ...props }) => {
  const fieldList = useMemo(() => Object.values(fields), [fields])
  return <Form fields={fieldList} {...props} />
}
