import React from "react"
import ReactModal from "react-modal"
import classes from "./modal.module.scss"
import clsx from "clsx"
ReactModal.setAppElement(document.getElementById(`root`))

/**
 * @param className
 * @param padding
 * @param {ReactModal.Props} props
 */
export function Modal({ className, padding = false, ...props }) {
  return (
    <ReactModal className={clsx(classes.ModalWindow, className)} overlayClassName={clsx(classes.ModalOverlay, padding ? classes.paddingLeft : "")} {...props} />
  )
}

