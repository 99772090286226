export const showPreloaderPage = (state = true, action) => {
  switch (action.type) {
    case "SET_SHOW_PRELOADER_PAGE":
      return action.payload
    default:
      return state
  }
}

export const setShowPreloaderPage = (payload) => ({
  type: `SET_SHOW_PRELOADER_PAGE`,
  payload,
})
