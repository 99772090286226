function fixLineBreaks(text) {
  return text?.replace(/\\n/gi, "\n")
}

/**
 * @param {{children: React.ReactChildren}} props
 */
export function UserText({ children }) {
  return fixLineBreaks(children) ?? null
}
